.bottomer {
	display: flex;
	flex-direction: column;
	justify-content: center; /* Center content vertically */
	align-items: center; /* Center content horizontally */
	padding: 20px; /* Add some padding */
	font-family:'Courier New', Courier, monospace;/* Use a modern font */
	font-size: 24px; /* Adjust font size as needed */
	font-weight: bold; /* Optional: Make the font bold */
	z-index: 99999999999999999999999999999999999999;
  }