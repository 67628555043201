@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("bg.jpeg");
  z-index: 1;
}

.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}
/* Rest of your CSS */



.container {
  z-index: 1;
  border: red 1px solid;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
}

.canvas-container {
  z-index: 1;
  flex: 1; /* Allows the canvas to grow and take up available space */
}

.bottomer-container {
  /* Styling for the Bottomer's container, ensuring it has enough space */
}

.annotation {
  /* transform: translate3d(-50%, -150%, 0); */
  /* text-align: left; */
  background: rgb(28, 28, 28);
  color: rgb(101, 212, 110);
  padding: 4px;
  border-radius: 100px;
  font-size: 1.5rem;
  margin-top: 50px;
  margin-left: -50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family:'Courier New', Courier, monospace;
  z-index: -999999999999999999999999;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px); /* Adjust as needed */
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


.dialog-fade-in-up {
  z-index: 999999999999999999999999999999999999999999999999999999999999;
  animation: fadeInUp 1s ease-in-out; /* Adjust duration as needed */
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(20);
  }
  to {
    opacity: 0;
    transform: translateY(50px); /* Adjust the value as needed */
  }
}

.dialog-fade-out-down {
  animation: fadeOutDown .8s ease-in-out forwards; /* 'forwards' will keep the final state after the animation */
}



.dialog {
  z-index: 999999999999999999999999999999999999999999999999999;
  width: 30vw;
  height: 80vh;
  background: rgb(28, 28, 28, 100);
  position: fixed;
  right: 0%;
  top: 20%;
  /* transform: translateY(-55%); */
  border-radius: 10px;
  padding: 8px;
  transform: translateY(0);
  font-family:'Courier New', Courier, monospace;
  color: rgb(101, 212, 110);
  text-align: center;
}



.dialog-header {
  font-family:'Courier New', Courier, monospace;
  color: rgb(101, 212, 110);
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 80px;
  padding: 0;
  text-align: center;
  z-index: 999999999999999999999999999999999999999999999999999;
}
svg {
  
  cursor: pointer;
}
.details {
  font-family:'Courier New', Courier, monospace;
  margin-top: 12px;
  color: rgb(101, 212, 110);
}

.dialog-fade-in-up {
  z-index: 9999999999999999999999999999999999999999999999999999;
  background: rgb(0,0,0,0);
}

.dialog-fade-out-down{
  background-color:rgb(0, 0, 0, 0);
}
.logo {
  z-index: 999999999999999999999999999999999999999999999999999999999999;
}

.cond {
  margin-top: 17%;
}

/* styles.css */
.loading-container {
  position: fixed; /* Fixed position */
  top: 50%;       /* Center vertically */
  left: 50%;      /* Center horizontally */
  transform: translate(-50%, 60%); /* Adjust for exact centering */
  z-index: 1000;  /* Ensure it's on top */
}
